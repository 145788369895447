<template>
  <font-awesome-icon
    icon="bars"
    size="2x"
    @click="toggleOffCanvasContent"
  />
</template>

<script>
export default {
  name: "MainSidebarToggle",
  methods: {
    toggleOffCanvasContent() {
      // if the menu is currently closed, open it
      if (this.$store.getters.getOffCanvasMenuStatus === false) {
        this.$store.commit("toggleOffCanvasMenu", {
          toggle: true,
        });
      } else {
        this.$store.commit("toggleOffCanvasMenu", {
          toggle: false,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .fa-bars {
    color: $white;
    cursor: pointer;
  }
</style>
